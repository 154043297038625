import type { Group } from "@jca/libs/api";

export const userGroupPermissionsSettings: {
	cognitoAllowedGroups: Group[];
	cognitoAdminGroups: Group[];
	cognitoAccountOwnerGroups: Group[];
	allowedGroupsToAddEngineeringUser: Group[];
	allowedGroupsToAddTechSupportUser: Group[];
	allowedGroupsToAddDealerOwnerUser: Group[];
	allowedGroupsToAddDealerUser: Group[];
	allowedGroupsToAddSalesUser: Group[];
	allowedGroupsToAddOrganizationOwnerUser: Group[];
	allowedGroupsToAddOrganizationMemberUser: Group[];
	allowedGroupsToSwitchOrganization: Group[];
	allowedGroupsToSwitchEnvironment: Group[];
	allowedGroupsToSystemSoftware: Group[];
	allowedGroupsToSoftwareUpdate: Group[];
	allowedGroupsToSystemStream: Group[];
	allowedGroupsToMapTesting: Group[];
	allowedGroupsToManagerEngineeringOrganization: Group[];
} = {
	cognitoAllowedGroups: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"Sales" as Group,
		"DealerOwner" as Group,
		"DealerUser" as Group,
	], // Manager and AccountOwner are not allowed to get access into the admin app for now
	cognitoAdminGroups: ["FlightPathAdmin" as Group],
	cognitoAccountOwnerGroups: ["Manager" as Group, "AccountOwner" as Group],
	allowedGroupsToAddEngineeringUser: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
	],
	allowedGroupsToAddTechSupportUser: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
	],
	allowedGroupsToAddDealerOwnerUser: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"Sales" as Group,
	],
	allowedGroupsToAddDealerUser: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"Sales" as Group,
		"DealerOwner" as Group,
	],
	allowedGroupsToAddSalesUser: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"Sales" as Group,
	],
	allowedGroupsToAddOrganizationOwnerUser: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"DealerOwner" as Group,
		"DealerUser" as Group,
	],
	allowedGroupsToAddOrganizationMemberUser: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"DealerOwner" as Group,
		"DealerUser" as Group,
		"AccountOwner" as Group,
		"Manager" as Group,
	],
	allowedGroupsToSwitchOrganization: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"Sales" as Group,
		"DealerOwner" as Group,
		"DealerUser" as Group,
	],
	allowedGroupsToSwitchEnvironment: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
	],
	allowedGroupsToSystemSoftware: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"DealerOwner" as Group,
		"DealerUser" as Group,
		"AccountOwner" as Group,
		"Manager" as Group,
		"Operator" as Group,
	],
	allowedGroupsToSoftwareUpdate: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
		"DealerOwner" as Group,
		"DealerUser" as Group,
		"AccountOwner" as Group,
		"Manager" as Group,
		"Operator" as Group,
	],
	allowedGroupsToSystemStream: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
	],
	allowedGroupsToMapTesting: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
	],
	allowedGroupsToManagerEngineeringOrganization: [
		"FlightPathAdmin" as Group,
		"Engineering" as Group,
		"TechSupport" as Group,
	],
};
